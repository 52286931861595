<template>
  <div>
    <p class="h4 pt-4 pb-4">角色管理</p>
    <el-form ref="form" :model="form" :inline="true">
      <el-form-item>
        <el-input
          v-model="form.username"
          size="small"
          placeholder="用户名"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model.number="form.role" size="small" placeholder="角色">
          <el-option label="全部角色" :value="0"></el-option>
          <!-- <el-option label="管理员" :value="1"></el-option> -->
          <el-option label="项目经理" :value="2"></el-option>
          <el-option label="发布者" :value="3"></el-option>
          <el-option label="标注员" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="query">查询</el-button>
        <el-button size="small" :disabled="!hasQuery" @click="reSet"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table class="mb-5" :data="userlist" border v-loading="loading">
      <el-table-column label="#" :width="80">
        <template slot-scope="scope">
          {{ curIndex(scope.$index) }}
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="role" label="角色">
        <template slot-scope="scope">
          <el-select
            :value="scope.row.role"
            size="small"
            @change="e => changeRole(scope.row, e, scope.$index)"
          >
            <!-- <el-option label="管理员" :value="1"></el-option> -->
            <el-option label="项目经理" :value="2"></el-option>
            <el-option label="发布者" :value="3"></el-option>
            <el-option label="标注员" :value="4"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="review" label="状态" width="100"
      :filters="[{text:'通过',value:'通过'},{text:'拒绝',value:'拒绝'},{text:'待审核',value:'待审核'}]"
      :filter-method="filterTag"
      filter-placement="bottom-end">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.review === '通过' ? 'success' : (scope.row.review === '拒绝' ? 'danger' : 'primary')"
            disable-transitions>{{scope.row.review}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="check(scope.row)">审核</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pager.page"
      :page-size="pager.pageSize"
      layout="total, prev, pager, next"
      :total="pager.totalRow"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getRolesList, updateRole } from '@/api/limit'

export default {
  name: 'app',
  components: {},
  data () {
    return {
      form: {
        role: '',
        username: ''
      },
      loading: true,
      pager: {
        page: 1,
        pageSize: 50,
        totalPage: 1,
        totalRow: 1
      },
      userlist: [],
      seed: false,
      hasQuery: false
    }
  },
  computed: {
    curIndex () {
      return index => {
        const { page = 1, pageSize = 10 } = this.pager
        return (page - 1) * pageSize + index + 1
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      const vm = this
      vm.getDatas()
    })
  },
  methods: {
    query () {
      if (this.seed) {
        return
      }
      this.hasQuery = true
      this.seed = true
      this.getDatas()
      setTimeout(() => {
        this.seed = false
      }, 500)
    },
    handleCurrentChange (val) {
      this.getDatas(val)
    },
    getDatas (page = 1) {
      this.loading = true
      const data = {
        page,
        pagesize: 10
      }
      if (this.form.role) {
        data.role = this.form.role
      }
      if (this.form.username) {
        data.username = this.form.username
      }
      getRolesList(data)
        .then(res => {
          const { data = [], code } = res
          if (code === 0) {
            this.pager = data.pager || {}
            this.userlist = data.userlist || []
          } else {
            this.$notify({
              type: 'error',
              title: '失败',
              message: res.message
            })
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    reSet () {
      this.form.username = ''
      this.form.role = ''
      this.hasQuery = false
      this.getDatas()
    },
    changeRole (e, newRole, index) {
      const data = {
        username: e.username,
        role: newRole
      }
      updateRole(data)
        .then(res => {
          if (res.code === 0) {
            this.$notify({
              type: 'success',
              title: '角色修改成功',
              message: res.message
            })
            this.userlist[index].role = newRole
          } else {
            this.$notify({
              type: 'error',
              title: '角色修改失败',
              message: res.message
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped></style>
