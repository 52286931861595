import request from '@/api/index.js'

export function projectData (projectId) {
  return request({
    url: `/api/projects/${projectId}`,
    method: 'get'
  })
}

export function getPuflag (data) {
  return request({
    url: '/api/project/getpuflag',
    method: 'post',
    data
  })
}

export function changePuflag (data) {
  return request({
    url: '/api/project/puflag',
    method: 'post',
    data
  })
}

export function delPuflag (data) {
  return request({
    url: '/api/project/deltaskusers',
    method: 'post',
    data
  })
}

export function addPuflag (data) {
  return request({
    url: '/api/project/addtaskusers',
    method: 'post',
    data
  })
}

export function getRolesList (params) {
  return request({
    url: '/api/user/list',
    method: 'get',
    params
  })
}

export function updateRole (data) {
  return request({
    url: '/api/user/updaterole`',
    method: 'post',
    data
  })
}

export function getRole () {
  return request({
    url: '/api/user/getrole',
    method: 'get'
  })
}
